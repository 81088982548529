import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

const Head = ({ title }) => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Helmet>
      <title>{` ${title} ${data.site.siteMetadata.title} `}</title>
      <meta
        name="keywords"
        content="Art, Artist, Portfolio, Website, Ceramic, Clay Work, Drawing, Ruzanna, Hanesyan, Ruzanna Hanesyan, RuzannaHanesyan"
      />
      <meta
        name="description"
        content="Ruzanna Hanesyan's CV/BIO, Works, Media/Press, Contact Information. Armenian born multimedia artist engaged with themes of body, language..."
      />
      <meta name="author" content="Lusine Hovseffyanss" />
      <meta name="robots" content="index,follow" />
    </Helmet>
  )
}

export default Head
